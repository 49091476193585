<template>
  <div>
    <CTabs>
      <CTab active>
        <template slot="title"
          ><CIcon name="cil-list" />
          {{ $t("role.profile.tab_infos.title") }}</template
        >
        <CRow>
          <CCol md="12">
            <ProfileInfoNew />
          </CCol>
        </CRow>
      </CTab>
      <CTab>
        <template slot="title">
          <CIcon name="cil-list" /> {{ $t("role.profile.tab_logos.title") }}
        </template>
        <br />
        <CRow>
          <CCol md="12">
            <ProfileLogo v-model="role" />
          </CCol>
        </CRow>
      </CTab>
      <!-- <CTab>
        <template slot="title">
          <CIcon name="cil-list" /> {{ $t("role.profile.tab_images.title") }}
        </template>
        <CRow>
          <CCol md="12">
            <ProfileImages />
          </CCol>
        </CRow>
      </CTab> -->
    </CTabs>
  </div>
</template>

<script>
// import { CircleStencil, Cropper } from "vue-advanced-cropper";
import { DataManager, Query } from "@syncfusion/ej2-data";
import { GetDataManager, ApiCall } from "../../../ds/index";
import DrfAdaptor from "../../../http/EJ2_adapter";

import ProfileInfoNew from "./ProfileInfoNew";
import ProfileLogo from "./ProfileLogo";
// import ProfileImages from "./ProfileImages";
import { apiUrl } from "../../../http";

export default {
  name: "ProfileData",
  data() {
    return {
      activeTab: 0,
      wallet: null,
      role: null,
    };
  },
  mounted() {
    // this.getFamily();
    this.getWallet();
    // this.getProducer();

    let self = this;
    // Update propduct Info
    ApiCall(
      GetDataManager("auth_frole", [], this.$store.state.role.id),
      new Query(),
      function (e) {
        // self.pp = e.result;
        self.role = e.result;
      }
    );
  },
  // computed: {
  //   role() {
  //     return this.$store.state.role;
  //   },
  // },
  methods: {
    // getFamily() {
    //   const dm = new DataManager({
    //     url:
    //       LOONITY_BASE_API_URL +
    //       "/api/auth/roles/" +
    //       this.$store.state.role.id +
    //       "/family/" +
    //       this.$store.state.role.family_id,
    //     adaptor: new DrfAdaptor(),
    //     crossDomain: true,
    //   });
    //   dm.executeQuery(new Query()).then((e) => {
    //     // this.family = e.result;
    //     let role = this.role;
    //     role.family = e.result;
    //     console.log(role);
    //     this.$store.commit("setRole", role);
    //   });
    // },
    getWallet() {
      const dm = new DataManager({
        url: apiUrl(
          `/api/auth/family/${this.$store.state.role.family_id}/wallet/`
        ),
        adaptor: new DrfAdaptor(),
        crossDomain: true,
      });
      dm.executeQuery(new Query()).then((e) => {
        this.wallet = e.result;
      });
    },
    // getProducer() {
    //   const dm = new DataManager({
    //     url:
    //       LOONITY_BASE_API_URL +
    //       "/api/auth/roles/" +
    //       this.$store.state.role.id +
    //       "/producer/" +
    //       this.$store.state.role.id,
    //     adaptor: new DrfAdaptor(),
    //     crossDomain: true,
    //   });
    //   dm.executeQuery(new Query()).then((e) => {
    //     this.producer = e.result;
    //     let role = this.role;
    //     role.producer = e.result;
    //     this.$store.commit("setRole", role);
    //   });
    // },
  },
  components: {
    // RoleMembers,
    // RoleAddress,
    // RoleImageRect,
    // RoleImageSquare,
    // RoleData,
    // RoleDataFamily,
    // RoleDataProducer,
    // RoleImageFamily,
    // ProfileImages,
    ProfileInfoNew,
    ProfileLogo,
  },
};
</script>
